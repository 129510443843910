<template>
  <a-modal v-model="visible" :title="title" width="680px" class="createStageModal">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="阶段名称" prop="phase_name">
          <a-input v-model="form.phase_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="阶段排序">
          <a-input-number style="width: 100%;" v-model="form.sequence"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="阶段负责人" prop="phase_owner">
          <a-select showSearch optionFilterProp="children" v-model="form.phase_owner">
            <a-select-option v-for="item in phaseOwnerList" :value="item.user_oid" :key="item.user_oid">
              {{ item.title ? item.name + '-' + item.title : item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="阶段状态" prop="phase_desc">
          <a-select v-model="form.phase_status" style="width: 100%">
            <a-select-option v-for="item in cat.phase_status" :key="item.oid" :value="item.cat_code">
              {{ item.cat_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始日期" prop="schedule_start_date">
          <a-date-picker valueFormat="YYYY/MM/DD" v-model="form.schedule_start_date" style="width: 100%;" />
        </a-form-model-item>
        <a-form-model-item label="结束日期" prop="schedule_end_date">
          <a-date-picker valueFormat="YYYY/MM/DD" v-model="form.schedule_end_date" style="width: 100%;" />
        </a-form-model-item>
      </div>
      <a-form-model-item label="阶段描述" prop="phase_desc">
        <a-textarea :rows="4" v-model="form.phase_desc"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <attachment-upload :source-oid="form.oid" :prj-oid="prjOid" source-type="5" @setOid="oid => form.oid = oid">
      <template slot="title">
        附件上传
      </template>
    </attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload.vue";
import autoInput from "@/components/autoInput.vue";
import { fetch } from "@/utils/request";
import catMixin from "@/utils/mixins/catMixin";
import dayjs from "dayjs";

export default {
  name: "createStageModal",
  props: {
    title: {
      type: String,
      default: '新建阶段'
    },
    phaseData: { // 编辑阶段需要传data
      type: Object
    },
    prjOid: {
      type: String
    }
  },
  mixins: [catMixin],
  data() {
    return {
      form: {
        oid: '',
        phase_name: '',
        sequence: '',
        phase_desc: '',
        schedule_start_date: '',
        schedule_end_date: '',
        phase_owner: '',
        phase_owner_name: '',
        phase_status: '',
        prjoid: ''
      },
      rules: {
        phase_name: [
          { required: true, message: "请填写阶段名称", trigger: 'blur' },
        ]
      },
      visible: false,
      backup: {},
      cat: {
        phase_status: []
      },
      phaseOwnerList: []
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { attachmentUpload, autoInput },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
    this.getPhaseOwnerList()
  },
  methods: {
    open(fill = {}) {
      this.visible = true
      this.form = {
        ...this.backup,
        ...fill
      }
      if (!fill.oid) {
        let company_info = JSON.parse(localStorage.getItem("company_info"))
        let find = this.phaseOwnerList.find(item => {
          return item.user_oid === company_info.account_oid
        })
        if (find) {
          this.form.phase_owner = find.user_oid
        }
        this.form.phase_status = "0"
        this.form.schedule_start_date = dayjs().format("YYYY/MM/DD")
        this.form.schedule_end_date = dayjs().add(1, "day").format("YYYY/MM/DD")
      }
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(valid, this.form);
          let url = this.title === '新建阶段' ? '/prj/phase/create' : '/prj/phase/update'
          fetch('post', url, {
            ...this.form,
            prjoid: this.prjOid
          }).then((res) => {
            if (res.status === 0) {
              let text = this.title === '新建阶段' ? '新建成功！' : '编辑成功！'
              this.$message.success(text)
              this.visible = false
              this.$emit('reload')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    getPhaseOwnerList() {
      fetch('post', '/prj/userlist/list', { prjoid: this.prjOid }).then((res) => {
        if (res.status === 0) {
          this.phaseOwnerList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
