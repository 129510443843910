<template>
  <div class="stage-overview" v-if="info">
    <div class="row-3">
      <div class="column-item info">
        <div class="title">阶段信息</div>
        <div class="info-body">
          <div class="body-item">
            <img class="pic" :src="info.owners[0]?.pic" v-if="info.owners?.[0]?.pic" alt="">
            <span v-else class="round"></span>
            <div class="right">
              <div>{{ info.phase_owner_name }}</div>
              <div class="name">项目负责人</div>
            </div>
          </div>
          <div class="body-row" style="display:flex;">
            <div class="body-item" style="margin-right: 30px;">
              <img class="pic" :src="$toUrl('shijian.png')" alt="">
              <div class="right">
                <div>{{ info.schedule_start_date }}</div>
                <div class="name">计划开始时间</div>
              </div>
            </div>
            <div class="body-item">
              <img class="pic" :src="$toUrl('shijian.png')" alt="">
              <div class="right">
                <div>{{ info.schedule_end_date }}</div>
                <div class="name">计划结束时间</div>
              </div>
            </div>
          </div>
          <div class="body-item">
            <img class="pic" :src="$toUrl('jingdu.png')" alt="">
            <div class="right">
              <div style="width: 250px;">
                <a-progress :percent="Number(info.complete_percent)" />
              </div>
              <div class="name">进度</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column-item">
        <div class="title">产品交付物统计</div>
        <div class="fill">
          <echarts :option="productOptions" height="100%" width="100%"></echarts>
        </div>
      </div>
      <div class="column-item">
        <div class="title">文件交付物统计</div>
        <div class="fill">
          <echarts :option="documentOptions" height="100%" width="100%"></echarts>
        </div>
      </div>
    </div>
    <div class="row-2">
      <div class="column-item">
        <div class="title">任务完成比统计</div>
        <div class="fill">
          <echarts :option="taskCompletedOptions" height="100%" width="100%"></echarts>
        </div>
      </div>
      <div class="column-item">
        <div class="title">任务人员分配统计</div>
        <div class="fill">
          <echarts :option="taskPersonOptions" height="100%" width="100%"></echarts>
        </div>
      </div>
    </div>
    <div class="row-2" v-if="$hasP('R_FINANCE')">
      <div class="column-item num">
        <div class="title">
          财务统计
          <div class="subtitle">全部/收入/支出</div>
        </div>
        <div class="content-box">
          <div class="left">
            <div class="text">财务收入<span class="num">{{ totalAdd|numberFormat }}</span>(万元)</div>
          </div>
          <div class="right">
            <div class="text">财务支出<span class="num">{{ totalSub|numberFormat }}</span>(万元)</div>
          </div>
        </div>
      </div>
      <div class="column-item">
        <div class="title">
          财务信息
        </div>
        <echarts :option="moneyOptions" height="100%" width="100%"></echarts>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from "@/components/echarts.vue";
import {fetch} from "@/utils/request.js";
import lodash from "lodash"
import dayjs from "dayjs";

export default {
  name: "stageOverview",
  components: { Echarts },
  props: {
    info: {
      type: [Object, Boolean],
      default: () => false
    }
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler() {
        this.getData()
      }
    }
  },
  data() {
    return {
      productData: [],
      documentData: [],
      productDocumentData: [],
      taskCompletedData: [],
      taskPersonData: [],
      moneyData: [],
      totalAdd: 0,
      totalSub: 0
    }
  },
  created() {
  },
  computed: {
    documentOptions() {
      return {
        tooltip: {
          trigger: 'item'
        },
        color: ['#0488ff', '#23b47e'],
        legend: {
          top: 'center',
          left: '5%',
          orient: "vertical",
          itemGap: 15
        },
        series: [
          {
            name: '文档交付物统计',
            type: 'pie',
            radius: ['40%', '70%'],
            left: "20%",
            labelLine: {
              show: false
            },
            label: {
              show: false,
            },
            data: ['应交付', '已交付'].map(text => {
              return {
                name: text,
                value: this.productData.find(item => item.value_name === text)?.value ?? 0
              }
            })
          }
        ]
      }
    },
    productOptions() {
      return {
        tooltip: {
          trigger: 'item'
        },
        color: ['#0488ff', '#23b47e'],
        legend: {
          top: 'center',
          left: '5%',
          orient: "vertical",
          itemGap: 15
        },
        series: [
          {
            name: '产品交付物统计',
            type: 'pie',
            radius: ['40%', '70%'],
            left: "20%",
            labelLine: {
              show: false
            },
            label: {
              show: false,
            },
            data: ['应交付', '已交付'].map(text => {
              return {
                name: text,
                value: this.productData.find(item => item.value_name === text)?.value ?? 0
              }
            })
          }
        ]
      }
    },
    taskCompletedOptions() {
      return {
        color: ['#0088FF'],
        grid: {
          top: '25%',
          bottom: '10%',
          left: "5%",
          right: "5%",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: this.taskCompletedData[0]?.value.map(item => item.value_name) ?? []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.taskCompletedData[0]?.value.map(item => Number(item.value)) ?? [],
            type: 'bar'
          }
        ]
      };
    },
    taskPersonOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        color: ['#0387ff', '#FEB70E', '#24b37D'],
        legend: {},
        grid: {
          top: "15%",
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.taskPersonData.map(item => item.key)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: ['未开始', '进行中', '已完成'].map(name => {
          return {
            name: name,
            type: 'bar',
            stack: 'c1',
            emphasis: {
              focus: 'series'
            },
            data: this.taskPersonData.map(item => {
              return item.value.find(i => i.value_name === name)?.value || 0
            })
          }
        })
      }
    },
    moneyOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.moneyData.map(item => item.date)
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: "单位：元"
          }
        ],
        series: [
          {
            name: '收入',
            type: 'line',
            connectNulls: true,
            itemStyle: {
              color: "#b5ef6e"
            },
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: this.moneyData.map(item => item.add)
          },
          {
            name: '支出',
            type: 'line',
            connectNulls: true,
            itemStyle: {
              color: "#ff7579"
            },
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: this.moneyData.map(item => item.sub)
          },
        ]
      };
    }
  },
  methods: {
    getData() {
      this.getProductAndDocument()
      // this.getDocument()
      this.getTaskCompleted()
      this.getTaskPerson()
      this.getMoney()
    },
    getProductAndDocument() {
      fetch("post", "/prj/phase/sta", {
        sta_type: 1,
        oid: this.info.oid
      }).then(res => {
        if (res.status === 0) {
          this.documentData = res.data.datas.find(item => item.key === '文档交付物').value
          this.productData = res.data.datas.find(item => item.key === '产品交付物').value
        }
      })
    },
    getTaskCompleted() {
      fetch("post", "/prj/phase/sta", {
        sta_type: 2,
        oid: this.info.oid
      }).then(res => {
        console.log(res.data)
        if (res.status === 0) {
          this.taskCompletedData = res.data.datas
        }
      })
    },
    getTaskPerson() {
      fetch("post", "/prj/phase/sta", {
        sta_type: 3,
        oid: this.info.oid
      }).then(res => {
        if (res.status === 0) {
          this.taskPersonData = res.data.datas
        }
      })
    },
    getMoney() {
      this.totalAdd = 0
      this.totalSub = 0
      fetch("post", "/prj/phase/sta", {
        oid: this.info.oid,
        sta_type: 4
      }).then(res => {
        if (res.status === 0) {
          let list = res.data.datas.sort((a, b) => {
            let aNum = dayjs(a.key, "YYYY-MM-DD").valueOf()
            let bNum = dayjs(b.key, "YYYY-MM-DD").valueOf()
            if (aNum < bNum) {
              return - 1
            } else if (aNum > bNum) {
              return 1
            } else {
              return 0
            }
          })
          let result = []
          for (let listElement of list) {
            let resultItem = {
              date: listElement.key,
              add: 0,
              sub: 0
            }
            if (listElement.value.length === 0) {
              continue;
            }
            listElement.value.forEach(item => {
              if (Number(item.value) > 0) {
                resultItem.add += item.value
              } else if (Number(item.value) < 0) {
                resultItem.sub += (item.value / -1)
              }
            })
            if (result.length) {
              resultItem.add += result.at(-1).add
              resultItem.sub += result.at(-1).sub
            }
            result.push(resultItem)
          }
          this.moneyData = result
          if (result.length) {
            this.totalAdd = lodash.round(lodash.divide(result.at(-1).add, 10000), 2)
            this.totalSub = lodash.round(lodash.divide(result.at(-1).sub, 10000), 2)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.stage-overview {
  height: 100%;
  overflow-y: auto;

  > div {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row-3 {
    height: 320px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 14px;
  }

  .row-2 {
    height: 320px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;
  }

  .column-item {
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(212, 212, 212, 0.3);
    padding: 20px 16px;
    display: flex;
    flex-direction: column;

    > .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4d4d;

      .subtitle {
        color: #A7A7A7;
        font-size: 12px;
        font-weight: 400;
      }
    }

    > .fill {
      flex: 1;
      overflow: hidden;
    }
  }

  .column-item.info {
    .info-body {
      margin-top: 28px;

      > .body-item, > .body-row {
        margin-bottom: 35px;
      }

      .body-item {
        display: flex;
        align-items: center;

        img, .round {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .round {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .round {
          background-color: #eee;
        }

        .right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          line-height: 1;

          font-size: 16px;
          color: #4D4D4D;
          font-weight: 500;

          .name {
            font-size: 12px;
            color: #A7A7A7;
            font-weight: 400;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .column-item.num {
    .content-box {
      display: flex;
      flex: 1;
      height: 176px;
      align-items: center;

      .left, .right {
        flex: 1;
        text-align: center;
        font-size: 16px;
        font-weight: 700;

        .num {
          font-size: 40px;
          margin: 0 10px;
        }

        &.left .num {
          color: #1cb969;
        }

        &.right .num {
          color: #ff2628;
        }
      }
    }
  }
}
</style>
