<template>
  <div :class="`app-main-content tab-${tabActive}`">
    <section class="stage-left">
      <div class="stage-left-title">阶段</div>
      <div class="stage-left-btn d-flex a-i-center" @click="openStageModal('新建阶段')">
        <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
        新建阶段
      </div>
      <div class="stage-left-items">
        <div class="stage-left-item" :class="[active === item.oid ? 'active' : '']" v-for="(item) in phaseList"
             :key="item.oid" @click="handleTabClick(item)">
          <div class="top d-flex a-i-center j-c-space-b">
            <div class="name a-style-ellipsis">{{ item.phase_name }}</div>
            <div :class="{
              'edp-status': true,
              blue: item.phase_status === '0',
              yellow: item.phase_status === '20',
              green: item.phase_status === '90'}">
              {{ item.phase_status_name }}
            </div>
          </div>
          <div class="bottom">
            <div class="info d-flex a-i-center j-c-space-b">
              <div class="date">{{ item.schedule_start_date || '未设置' }} - {{
                  item.schedule_end_date || '未设置'
                }}
              </div>
              <div class="percent">{{ item.complete_percent }}%</div>
            </div>
            <div class="progress">
              <a-progress :percent="item.complete_percent - 0" size="small" :show-info="false" :strokeWidth="4" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card-group">
      <card
          :tabs="[{cat_name: '任务', cat_code: '1'}, {cat_name: '概览', cat_code: '2'}]"
          :allow="false"
          :title="currentStage?.phase_name"
          body-class="overflow-hidden page-card-body"
          v-model="tabActive">
        <template slot="head-end">
          <a-dropdown :trigger="['click']">
            <img style="width: 18px; height: 18px;" :src="$toUrl('edit23.png')" alt="">
            <a-menu slot="overlay">
              <a-menu-item @click="openStageModal('编辑阶段')">
                编辑阶段
              </a-menu-item>
              <template v-if="hasPhaseDeleteP()">
                <a-menu-item @click="delPhase">
                  删除阶段
                </a-menu-item>
              </template>
            </a-menu>
          </a-dropdown>
        </template>
        <template #tools v-if="tabActive === '1'">
          <div class="left-action">
            <a-button type="primary" @click="openTaskModal" v-if="hasAddTaskP">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新建任务
            </a-button>
            <a-button type="primary" @click="openMilestoneModal">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              里程碑
            </a-button>
            <span class="num">共 {{ issueList.length }} 个</span>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">排序</span>
              <a-select class="sort-select" placeholder="请选择" v-model="orderby"
                        @change="search">
                <a-select-option :value="item.value" v-for="item in orderbyList" :key="item.value">{{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <a-input placeholder="请输入标题或者编码" style="width: 180px" v-model="seach">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button type="primary" :disabled="selectedRowKeys.length === 0" @click="openBatchChangeModal">批量调整
              </a-button>
            </div>
            <div class="item">
              <a-button @click="isFilter = !isFilter">筛选
                <a-icon class="filter" :class="{rotate:isFilter}" type="double-right" />
              </a-button>
            </div>
            <div class="item">
              <a-button class="search" @click="search">查询</a-button>
            </div>
            <div class="item">
              <a-dropdown>
                <img class="more" :src="$toUrl('more.png')">
                <a-menu slot="overlay" @click="selectActionMenu">
                  <a-menu-item key="download">普通任务模板</a-menu-item>
                  <a-menu-item v-if="importV2" key="downloadV2">多级任务模板</a-menu-item>
                  <a-menu-item v-if="hasAddTaskP" key="import">普通任务导入</a-menu-item>
                  <a-menu-item v-if="hasAddTaskP&&importV2" key="importV2">多级任务导入</a-menu-item>
                  <a-menu-item key="export">普通任务导出</a-menu-item>
                  <a-menu-item v-if="importV2" key="exportV2">多级任务导出</a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
        <div class="filter-container" v-show="!isFilter">
          <div class="title">筛选条件</div>
          <div class="content">
            <div class="item">
              <div class="label">计划开始</div>
              <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                              v-model="schedule_start_date" />
            </div>
            <div class="item">
              <div class="label">计划结束</div>
              <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                              v-model="schedule_end_date" />
            </div>
            <div class="item">
              <div class="label">实际开始</div>
              <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                              v-model="actual_start_date" />
            </div>
            <div class="item">
              <div class="label">实际结束</div>
              <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                              v-model="actual_end_date" />
            </div>
            <div class="item">
              <div class="label">创建时间</div>
              <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                              v-model="create_time" />
            </div>
            <div class="item">
              <div class="label">状态</div>
              <a-select v-model="issue_status" @change="search">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="item in cat.issue_bstatus" :key="item.oid" :value="item.cat_code">
                  {{ item.cat_name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <div class="label">优先级</div>
              <a-select v-model="issue_level" @change="search">
                <a-select-option v-for="item in [{cat_code:'',cat_name:'全部'},...cat.issues_level]"
                                 :key="item.cat_code" :value="item.cat_code">
                  {{ item.cat_name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <div class="label">标签</div>
              <a-select style="width: 200px;height: 32px;overflow: hidden;" v-model="issue_tags"
                        placeholder="请选择标签" mode="multiple" @change="search" :maxTagCount="1" allowClear>
                <a-select-option v-for="item in cat.issue_tags" :key="item.cat_code" :value="item.cat_name">
                  {{ item.cat_name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <span class="label">负责人</span>
              <a-select
                  @change="search"
                  class="sort-select"
                  placeholder="请选择"
                  mode="multiple"

                  :maxTagCount="1"
                  v-model="issue_owners">
                <a-select-option v-for="item in accountList" :key="item.oid" :value="item.user_oid">{{
                    item.title ? item.name + '-' + item.title : item.name
                  }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <span class="label" style="width: 64px;">协同人</span>
              <a-select
                  @change="search"
                  class="sort-select"
                  placeholder="请选择协同人"
                  mode="multiple"

                  :maxTagCount="1"
                  v-model="issue_assists">
                <a-select-option v-for="item in accountList" :key="item.oid" :value="item.user_oid">{{
                    item.title ? item.name + '-' + item.title : item.name
                  }}
                </a-select-option>
              </a-select>
            </div>

          </div>
        </div>
        <template v-if="tabActive === '1'">
          <div class="body-flex">
            <div class="body-left-table">
              <vxe-table
                  ref="xTable"
                  height="100%"
                  :scroll-y="{enabled: true, gt: 50}"
                  :scroll-x="{enabled: true, gt: 0}"
                  :data="issueList"
                  :show-overflow="false"
                  :row-config="{
                  keyField: 'oid',
                  isHover: true,
                  isCurrent: true
                }"
                  :checkbox-config="{
                  checkStrictly: true
                }"
                  :tree-config="{
                  reserve: true,
                  transform: true,
                  expandAll: true,
                  parentField: 'parent_oid',
                  rowField: 'oid',
                  iconOpen: 'iconfont icon-xiangxia expand-icon',
                  iconClose: 'iconfont icon-xiangxia expand-icon open'
                }"
                  @current-change="changeCurrentRow"
                  :column-config="{resizable: true}"
              >
                <vxe-column type="checkbox" width="50">
                  <template #header>
                    <a-checkbox
                        @change="toggleAllCheck()"
                        :indeterminate="check.all_indeterminate"
                        :checked="check.all_indeterminate ? undefined : check.all_checked"
                    />
                  </template>
                  <template #checkbox="{ row }">
                    <a-checkbox
                        @change="toggleCheck(row)"
                        :checked="selectedRowKeys.includes(row.oid)"
                    />
                  </template>
                </vxe-column>
                <vxe-column field="seqid" title="ID" width="80px">
                  <template v-slot="{row}">
                    <span style="white-space: nowrap">{{ row.seqid }}</span>
                  </template>
                </vxe-column>
                <vxe-column title="任务编码" :resizable="true" width="150px">
                  <template v-slot="{row}">
                    <a-tooltip>
                      <template slot="title">{{ row.issue_code || '暂无' }}</template>
                      <edp-input
                          style="--width: 100%"
                          v-model="row.issue_code"
                          :db="dbs.prjIssue"
                          field-name="issue_code"
                          :oid="row.oid"
                          :prj-oid="row.prjoid"
                          @change="() => {
                          $refs.issueDetail.getDetail()
                          getIssueByPhase()
                        }"
                      >
                      </edp-input>
                    </a-tooltip>
                  </template>
                </vxe-column>
                <vxe-column title="标题" :resizable="true" :width="(maxDeep * 25 + 250) + 'px'" tree-node>
                  <template v-slot="{row}">
                    <div class="edp-title">
                      <template v-if="row.issue_type_name === '里程碑'">
                        <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('milepost_red.png')"
                             alt="">
                        <img v-else-if="row.issue_status === '0'" :src="$toUrl('milepost_blue.png')" alt="">
                        <img v-else-if="row.issue_status === '1'" :src="$toUrl('milepost_yellow.png')" alt="">
                        <img v-else-if="row.issue_status === '100'" :src="$toUrl('milepost_green.png')" alt="">
                      </template>
                      <template v-else-if="row.issue_type_name === '任务'">
                        <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')"
                             alt="">
                        <img v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                        <img v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                        <img v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                      </template>
                      <a-tooltip>
                        <template slot="title">{{ row.issue_name }}</template>
                        <span class="name">{{ row.issue_name }}</span>
                      </a-tooltip>
                      <a-tooltip v-if="row.issue_type_name === '里程碑'">
                        <template slot="title">里程碑详情</template>
                        <router-link class="hidden"
                                     :to="{path: '/project/plan/milestoneDetails', query: {oid: row.oid, prj_oid}}">
                          <i class="iconfont icon-V"></i>
                        </router-link>
                      </a-tooltip>
                      <a-tooltip v-else-if="hasAddTaskP">
                        <template slot="title">新增子任务</template>
                        <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt=""
                             @click="openChildrenTaskModal(row)">
                      </a-tooltip>
                      <a-tooltip>
                        <template slot="title">设置前置任务项</template>
                        <i class="iconfont icon-guanlian hidden" @click="openRelevancyWorkModal(row)"></i>
                      </a-tooltip>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column title="前置任务" width="100px">
                  <template v-slot="{row}">
                    <a-tooltip>
                      <template slot="title">{{ row.pretasks || '暂无' }}</template>
                      <edp-input
                          style="--width: 100%"
                          v-model="row.pretasks"
                          :db="dbs.prjIssue"
                          field-name="pretasks"
                          :oid="row.oid"
                          :prj-oid="row.prjoid"
                          @change="$refs.issueDetail.getDetail()"
                      >
                      </edp-input>
                    </a-tooltip>
                  </template>
                </vxe-column>
                <vxe-column title="优先级" width="85px">
                  <template v-slot="{row}">
                    <span
                        class="edp-tag"
                        :class="{
                        green: row.issue_level === '100',
                        blue: row.issue_level === '200',
                        yellow: row.issue_level === '300',
                        red: row.issue_level === '400',
                      }"
                        v-if="row.issue_level">{{ row.issue_level_name }}</span>
                  </template>
                </vxe-column>
                <vxe-column title="进度" width="80px">
                  <template v-slot="{row}">
                    <span v-if="row.children?.length > 0 || !$hasP('T_UPDATEALL')">
                      {{ row.complete_percent ? `${row.complete_percent}%` : `` }}
                    </span>
                    <div v-else @click.stop class="stop">
                      <edp-input
                          size="mini"
                          number
                          style="--width: 60px"
                          :db="dbs.prjIssue"
                          field-name="complete_percent"
                          v-model="row.complete_percent"
                          :oid="row.oid"
                          :prj-oid="row.prjoid"
                          :precision="0"
                      >
                        <span>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
                      </edp-input>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column title="状态" width="85px" field="issue_status">
                  <template v-slot="{row}">
                    <span
                        class="edp-status"
                        :class="{
                        blue: row.issue_status === '0',
                        yellow: row.issue_status === '1',
                        green: row.issue_status === '100',
                      }"
                        v-if="row.issue_status"
                    >
                      {{ row.issue_status_name }}
                    </span>
                  </template>
                </vxe-column>
                <vxe-column title="负责人" width="160px">
                  <template v-slot="{row}">
                    <div class="edp-owners">
                      <div v-for="item in row.owners" :key="item.oid">
                        <a-avatar :size="16" :src="item.pic" />
                        <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>
                      </div>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column title="协同人" width="170px">
                  <template v-slot="{row}">
                    <div class="edp-owners">
                      <div v-for="item in row.assists" :key="item.oid">
                        <a-avatar :size="16" :src="item.pic" style="margin-right: 5px;" />
                        <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>
                      </div>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column title="附件" width="110px">
                  <template v-slot="{row}">{{ row.has_file === '1' ? '有' : '空'}}</template>
                </vxe-column>
                <vxe-column title="计划开始" field="schedule_start_date" width="110px"></vxe-column>
                <vxe-column title="实际开始" field="actual_start_date" width="110px"></vxe-column>
                <vxe-column title="计划结束" field="schedule_end_date" width="110px"></vxe-column>
                <vxe-column title="实际结束" field="actual_end_date" width="110px">
                </vxe-column>
                <vxe-column title="创建时间" field="create_time" width="110px">
                </vxe-column>
              </vxe-table>
            </div>
            <issue-detail ref="issueDetail" :oid="cur_issue_oid" @reload="getPrjPhaseList"></issue-detail>
          </div>
        </template>
      </card>
      <div class="overview" v-if="tabActive === '2'">
        <stage-overview :info="currentStage"></stage-overview>
      </div>
    </div>
    <create-stage-modal
        ref="stageModal"
        :title="stageModalTitle"
        :phase-data="stageModalData"
        :prj-oid="prj_oid"
        @reload="()=>{
        getPrjPhaseList();
        $refs.milestoneModal.getPhaseList();
      }"
    ></create-stage-modal>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="reload" />
    <create-task-modal ref="taskModal" @reload="reload"></create-task-modal>
    <create-milestone-modal ref="milestoneModal" :prj-oid="prj_oid" @reload="reload"></create-milestone-modal>
    <import-file url="/prj/issue/import" :query="{prjoid: prj_oid}" ref="import"
                 @response="importSuccess"></import-file>
    <import-file url="/prj/issue/importV2" :query="{prjoid: $route.query.prj_oid}" ref="importV2"
                 @response="importSuccess2"></import-file>
    <batch-change-modal @reload="reload" ref="batchChange"></batch-change-modal>
  </div>
</template>

<script>
import issueDetail from "@/components/issueDetail.vue";
import createStageModal from "@/components/createStageModal.vue";
import createTaskModal from "@/components/createTaskModal.vue";
import createMilestoneModal from "@/components/createMilestoneModal.vue";
import { fetch } from "@/utils/request";
import StageOverview from "@/components/stageOverview.vue";
import importFile from "@/components/importFile.vue";
import { ChildData2Vxe, getTemplate } from "@/utils/helpFunc";
import { h } from "vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import EdpInput from "@/components/e-form/edp-input.vue";
import BatchChangeModal from "@/components/batchChangeModal.vue";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";
import catMixin from "@/utils/mixins/catMixin";
import lodash from "lodash";

export default {
  name: "projectStage",
  mixins: [catMixin],
  data() {
    return {
      isFilter: false,
      cat: {
        import_issue: [],
        issue_bstatus: [],
        issues_level: [],
        issue_tags: []
      },
      tabActive: "1",
      active: '',
      visible: false,
      prj_oid: '',
      cur_issue_oid: '',
      phaseList: [],
      issueList: [],
      selectedRowKeys: [],
      seach: '',
      issue_level: '',
      issue_status: '',
      issue_owners: [],
      issue_assists: [],
      issue_tags: [],
      schedule_start_date: [],
      schedule_end_date: [],
      actual_start_date: [],
      actual_end_date: [],
      create_time: [],
      accountList: [],
      orderby: 'a.create_time asc',
      orderbyList: [
        { value: 'a.create_time asc', name: '创建时间正序' },
        { value: 'a.create_time desc', name: '创建时间倒序' },
        { value: 'a.issue_level asc', name: '优先级正序' },
        { value: 'a.issue_level desc', name: '优先级倒序' },
        { value: 'a.actual_start_date asc', name: '实际开始时间正序' },
        { value: 'a.actual_start_date desc', name: '实际开始时间倒序' },
        { value: 'a.schedule_start_date asc,a.schedule_end_date asc', name: '计划开始时间正序' },
        { value: 'a.schedule_start_date desc', name: '计划开始时间倒序' },
        { value: 'a.schedule_end_date asc', name: '计划结束时间正序' },
        { value: 'a.schedule_end_date desc', name: '计划结束时间倒序' },
        { value: 'a.issue_status asc', name: '状态升序' },
        { value: 'a.issue_status desc', name: '状态倒序' },
      ],
      stageModalTitle: '',
      stageModalData: {},
      expandKeys: [],
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue"),
      },
      maxDeep: 1,
    }
  },
  computed: {
    importV2() {
      return this.cat.import_issue.filter(item => item.cat_code === "001")[0]?.field_value === '1'
    },
    checkedList() {
      return this.issueList.filter(item => {
        return this.selectedRowKeys.includes(item.oid)
      })
    },
    check() {
      let select = this.checkedList;
      return {
        all_indeterminate: select.length !== 0 && select.length !== this.issueList.length,
        all_checked: select.length === this.issueList.length
      }
    },
    currentStage() {
      if (this.active) {
        return this.phaseList.find(item => item.oid === this.active)
      }
      return false
    },
    hasAddTaskP() {
      return this.$hasP('T_CREATE')
    },
  },
  watch: {
    // selectedRowKeys(val, pre) {
    //   const result = [...val]
    //   const findRow = (oid, list = this.issueList) => {
    //     const find = list.find(item => item.oid === oid)
    //     if (find) {
    //       return find
    //     } else {
    //       for (let item of list) {
    //         if (Array.isArray(item.children)) {
    //           let result = findRow(oid, item.children)
    //           if (result) {
    //             return result
    //           }
    //         }
    //       }
    //       return false
    //     }
    //   }
    //   let diff = val.filter(item => {
    //     return !pre.includes(item)
    //   }).map(item => findRow(item)).filter(item => item !== false)
    //   const deepSetChecked = (list) => {
    //     for (let item of list) {
    //       if (!result.includes(item.oid)) {
    //         result.push(item.oid)
    //       }
    //       if (Array.isArray(item.children)) {
    //         deepSetChecked(item.children)
    //       }
    //     }
    //   }
    //   deepSetChecked(diff)
    //   if (JSON.stringify(this.selectedRowKeys) !== JSON.stringify(result)) {
    //     this.selectedRowKeys = result
    //   }
    // }
  },
  components: {
    RelevancyWorkModal,
    BatchChangeModal,
    EdpInput, StageOverview, issueDetail, createStageModal, createTaskModal, importFile, createMilestoneModal
  },
  created() {
    this.getAccount()
    let { prj_oid } = this.$route.query
    this.prj_oid = prj_oid
  },
  mounted() {
    this.getPrjPhaseList()
  },
  methods: {
    changeCurrentRow({ row }) {
      this.cur_issue_oid = row.oid
    },
    toggleCheck(row) {
      let index = this.selectedRowKeys.indexOf(row.oid)
      console.log(index)
      if (index !== -1) {
        this.selectedRowKeys.splice(index, 1)
        this.$forceUpdate()
      } else {
        let result = []
        let deepSetChecked = (parent_oid) => {
          let filter = this.issueList.filter(item => {
            return item.parent_oid === parent_oid && !this.selectedRowKeys.includes(item.oid)
          })
          filter.forEach(item => {
            deepSetChecked(item.oid)
            result.push(item.oid)
          })
        }
        deepSetChecked(row.oid)
        this.selectedRowKeys.push(row.oid, ...result)
      }
    },
    toggleAllCheck() {
      if (this.check.all_indeterminate || !this.check.all_checked) {
        this.selectedRowKeys = this.issueList.map(item => item.oid)
      } else if (this.check.all_checked) {
        this.selectedRowKeys = []
      }
    },
    changeSelected(list) {
      this.selectedRowKeys = list
    },
    openRelevancyWorkModal(row) {
      this.$refs.relevancyWorkModal.prjoid = row.prjoid
      this.$refs.relevancyWorkModal.open(row)
    },
    getAccount() {
      fetch("post", "/prj/userlist/list", { prjoid: this.$route.query.prj_oid }).then(res => {
        if (res.status === 0) {
          this.accountList = res.data
        }
      })
    },
    openBatchChangeModal() {
      this.$refs.batchChange.open(this.selectedRowKeys.join(","))
      this.$refs.batchChange.form.prjoid = this.prj_oid
      this.$refs.batchChange.onChangePrj()
      this.$refs.batchChange.getPhaseList().then(() => {
        this.$refs.batchChange.form.phase_oid = this.active
      })
    },
    hasPhaseDeleteP() {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      if (this.currentStage?.phase_owner?.split('/').includes(oid) || this.currentStage?.creator_oid === oid) {
        return true
      }
      return this.$hasP('H_DEL', this.prj_oid)
    },
    reload() {
      this.getPrjPhaseList()
      this.$refs.issueDetail.reloadMoneyModalIssueList()
    },
    handleTabClick(item) {
      this.active = item.oid
      this.cur_issue_oid = ''
      console.log(this.currentStage)
      this.getIssueByPhase()
    },
    openStageModal(title) {
      this.stageModalTitle = title
      if (title === '编辑阶段') {
        console.log(this.active);
        this.$refs.stageModal.open(JSON.parse(JSON.stringify(this.currentStage)))
      } else {
        this.$refs.stageModal.open()
      }
    },
    getPrjPhaseList() {
      fetch('post', '/prj/phase/retrieve/byprj', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          console.log('阶段', data)
          this.phaseList = data
          if (this.phaseList?.length > 0) {
            !this.active && (this.active = this.phaseList[0].oid)
            this.getIssueByPhase()
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    search() {
      this.getIssueByPhase()
    },
    getIssueByPhase() {
      // this.issueList = []
      this.selectedRowKeys = []
      let query = {
        prjoid: this.prj_oid,
        phase_oid: this.currentStage.oid,
        keywords: this.seach,
        orderby: this.orderby,
        issue_status: this.issue_status,
        issue_level: this.issue_level,
        issue_owners: this.issue_owners.join(","),
        issue_assists: this.issue_assists.join(","),
        issue_tags: this.issue_tags.join(","),
      }
      if (this.schedule_start_date[0]) {
        query.schedule_start_date_begin = this.schedule_start_date[0]
        query.schedule_start_date_end = this.schedule_start_date[1]
      }
      if (this.schedule_end_date[0]) {
        query.schedule_end_date_begin = this.schedule_end_date[0]
        query.schedule_end_date_end = this.schedule_end_date[1]
      }
      if (this.actual_start_date[0]) {
        query.actual_start_date_begin = this.actual_start_date[0]
        query.actual_start_date_end = this.actual_start_date[1]
      }
      if (this.actual_end_date[0]) {
        query.actual_end_date_begin = this.actual_end_date[0]
        query.actual_end_date_end = this.actual_end_date[1]
      }
      if (this.create_time[0]) {
        query.create_time_begin = this.create_time[0]
        query.create_time_end = this.create_time[1]
      }
      const oldQuery = {
        prjoid: this.prj_oid,
        phase_oid: this.currentStage.oid,
        keywords: "",
        orderby: "a.create_time asc",
        issue_status: "",
        issue_level: "",
        issue_owners: "",
        issue_assists: "",
        issue_tags: "",
      }
      delete query.select_tag;
      const isEqual = lodash.isEqual(query, oldQuery)
      if (!isEqual) {
        query.select_tag = true
      }
      fetch('post', '/prj/issue/get', query).then(async (res) => {
        if (res.status === 0) {
          let vxeData = ChildData2Vxe(res.data.map(e => {
            return {
              ...e,
              children: e.end_flag === '1' ? e.children : undefined
            }
          }))
          let data = vxeData.data
          this.maxDeep = vxeData.maxDeep
          this.issueList = data
          if (!this.cur_issue_oid && data[0]?.oid) {
            this.cur_issue_oid = data[0]?.oid
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    delPhase() {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          fetch('post', '/prj/phase/delete', {
            oid: this.currentStage.oid
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功！')
              if (this.phaseList.length) {
                this.active = this.phaseList[0].oid
                this.cur_issue_oid = ""
              }
              this.getPrjPhaseList()
              this.$refs.milestoneModal.getPhaseList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    customRow(row) {
      return {
        on: {
          click: () => {
            console.log(row);
            this.cur_issue_oid = row.oid
            // this.rightData = row
          }
        }
      }
    },
    customExpandIcon(props) {
      // 是否有子项，无子项不展示图标
      // record是每行的数据，后端返回的数据会有一个hasChild字段判断是project/list否有子项
      if (props.record && props.record.end_flag === '1') {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
          // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon",
            on: { click: e => props.onExpand(props.record, e) }
          })
        } else {
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon open",
            on: { click: e => props.onExpand(props.record, e) }
          })
        }
      } else {
        return h("span", { style: 'padding-left: 27px' })
      }
    },
    openTaskModal() {
      this.$refs.taskModal.open({
        prjoid: this.$route.query.prj_oid,
        phase_oid: this.currentStage.oid,
        phase_name: this.currentStage.phase_name,
      })
    },
    openMilestoneModal() {
      this.$refs.milestoneModal.open({
        prjoid: this.$route.query.prj_oid,
        phase_oid: this.currentStage.oid,
        phase_name: this.currentStage.phase_name,
      })
    },
    openChildrenTaskModal(row) {
      console.log(row);
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        route_level: row.route_level - 0 + 1,
        route_map: row.route_map + '/' + row.oid,
        phase_oid: row.phase_oid
      })
    },
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.import.upload()
      } else if (key === 'importV2') {
        this.$refs.importV2.upload()
      } else if (key === "export") {
        // console.log(this.issueList.map(item => item.oid).join(','));
        if (this.issueList.length > 0) {
          window.open(this.$toUrl(`/prj/issue/export?issueoids=${this.issueList.map(item => item.oid).join(',')}`, true, "api"))
        } else {
          this.$message.error('暂无任务')
        }
      } else if (key === "exportV2") {
        if (this.selectedRowKeys.length > 0) {
          window.open(this.$toUrl(`/prj/issue/exportV2?issueoids=${this.selectedRowKeys.join(',')}`, true, "api"))
        } else {
          this.$message.error('请勾选任务')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '任务项导入模板.xlsx')
      } else if (key === "downloadV2") {
        getTemplate('/attachment/model', '多级任务模板.xlsx')
      }
    },
    importSuccess(res) {
      if (res.status === 0) {
        this.$message.success("导入成功")
        this.getPrjPhaseList()
      } else {
        this.$message.error(res.message)
      }
    },
    importSuccess2(res) {
      if (res.status === 0) {
        this.$message.success("正在导入中，请30分钟后查看！")
        // this.getPrjPhaseList()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.filter-container {
  margin-bottom: 12px;

  .title {
    font-size: 18px;
    color: #000000;
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 12px;

      .label {
        margin-right: 10px;
      }

      .ant-calendar-picker {
        width: 300px;
      }

      .ant-select {
        width: 180px;
      }
    }
  }
}

.filter {
  transition: all .3s;
  transform: rotate(-90deg);

  &.rotate {
    transform: rotate(90deg)
  }
}

.app-main-content {
  display: flex;
  padding: 10px 20px;

  /deep/ .vxe-table--render-default {
    .vxe-table--header-wrapper {
      background-color: #f8f8f8;

      .vxe-body--x-space {
        background-color: #f8f8f8;
      }

      .vxe-header--column {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .vxe-body--row {
      &.row--current {
        background-color: initial;
      }

      &.row--hover {
        background-color: #fff4e6;
      }

      .vxe-tree--btn-wrapper {
        display: flex;
      }
    }
  }

  .edp-title {
    width: 100%;
    display: flex;
    align-items: center;

    .name {
      flex: 1;
      max-width: initial;
    }

    .actions {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      > *:not(:last-child) {
        margin-right: 5px;
      }

      a {
        color: #494949;
      }
    }
  }

  .card-group {
    overflow: hidden;
    width: 100%;
    flex: 1;
    height: 100%;

    .edp-card {
      height: 100%;

      /deep/ .edp-card-body {
        flex: 1;

        .body-left-table {
          overflow-y: auto;
        }
      }
    }
  }

  &.tab-2 {
    .card-group {
      flex: 1;
      display: flex;
      flex-direction: column;

      .overview {
        flex: 1;
        overflow: hidden;
        margin-top: 10px;
      }
    }

    .edp-card {
      height: 53px !important;
      box-shadow: 0 2px 10px 0 #d4d4d44d;

      /deep/ .edp-card-head {
        border-bottom: none;
      }

      /deep/ .edp-card-body {
        display: none;
      }
    }
  }

  .stage-left {
    width: 240px;
    height: 100%;
    background: #FFF;
    margin-right: 14px;
    flex-shrink: 0;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

    .stage-left-title {
      padding: 16px 0 40px 20px;
      font-size: 18px;
      font-weight: 500;
    }

    .stage-left-btn {
      font-size: 16px;
      font-weight: 500;
      padding: 0 0px 20px 20px;
      width: 120px;
      cursor: pointer;

      i {
        display: block;
        font-size: 14px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .stage-left-items {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      height: calc(100% - 130px);
      overflow-y: auto;

      .stage-left-item {
        width: 220px;
        //height: 80px;
        border-radius: 2px 2px 2px 2px;
        padding: 12px 10px;
        flex-shrink: 0;
        cursor: pointer;

        &:hover {
          background: fadeout(@primary-color, 90%);
        }

        &.active {
          background: fadeout(@primary-color, 90%);
        }

        .top {
          margin-bottom: 9px;

          .name {
            width: 130px;
            flex-shrink: 0;
            font-size: 16px;
            font-weight: 500;
          }

          .status {
            flex-shrink: 0;
            font-size: 12px;
            font-weight: 700;
            color: var(--color);
            border: 1px solid var(--color);
            border-radius: 20px;
            padding: 1px 8px;
            margin-left: 8px;
          }

          .status.blue {
            --color: #0088FF;
          }

          .status.yellow {
            --color: #FCBD26;
          }

          .status.green {
            --color: #17C665;
          }

          .status.grey {
            --color: #ADADAD;
          }
        }

        .bottom {
          /deep/ .ant-progress-inner {
            background-color: #DEDEDE;
          }

          .info {
            font-size: 12px;
            font-weight: 500;

            .percent {
              font-size: 12px;
              color: #A7A7A7;
              font-weight: 400;
            }
          }
        }
      }
    }

    /*滚动条样式*/

    ::-webkit-scrollbar { /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
    }

    ::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-track { /*滚动条里面轨道*/
      -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .left-action {
    .ant-btn {
      margin-right: 10px;
    }

    .num {
      font-size: 14px;
    }
  }

  /deep/ .page-card-body {
    padding: 10px 0px 0 10px;
    display: flex;
    flex-direction: column;
    // 表格上方的操作栏
    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      // 左侧表格
      > .body-left-table {
        flex: 1;
        flex-shrink: 0;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        background-color: #FFF;
      }

    }
  }

  .edp-card-head-right {
    > i {
      cursor: pointer;
    }
  }
}

.createStageModal {
  /deep/ .ant-modal-body {
    overflow-y: auto;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

/deep/ .ant-table-body {
  .ant-table-tbody {
    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}
</style>
